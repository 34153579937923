export interface CanFullName {
  firstName?: string;
  lastName?: string;
}

export function fullName(
  subject?: CanFullName,
  defaultName = 'No Name',
): string {
  if (!subject || (!subject.firstName && !subject.lastName)) return defaultName;

  const { firstName = '', lastName = '' } = subject;

  return `${firstName} ${lastName}`;
}
