import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';
import { useApiStore } from './api';
import { updateRefFromApi } from '@/lib/api-request';
import { BookingResponse } from '@shared/bookings';
import { SitterResponse } from '@shared/sitters';
import { FAQResponse } from '@shared/franchises';
import { BookingsController, SupportActionsController } from '@/lib/api-routes';
import { Payment } from '@/lib/shared-types';

export const useSitterStore = defineStore('sitter', () => {
  const api = useApiStore();

  const sitter = computed(() => {
    if (api.user?.userType != 'Sitter') return undefined;
    return api.user.organisation as SitterResponse;
  });

  const sitterProfile = ref<SitterResponse>();

  async function getBookingsRequiringAttention(
    bookingsRequiringAttention: Ref<BookingResponse[] | undefined>,
  ) {
    return await updateRefFromApi(
      bookingsRequiringAttention,
      BookingsController.findAttentionRequired,
    );
  }

  const privacyPolicy = ref('');
  const terms = ref('');

  // Sitter Profile Radio Routes
  const radioRoutes = new Map([
    ['Edit Account', 'sitter_my_profile'],
    ['Profile Questions', 'sitter_profile_questions'],
    ['File Uploads', 'sitter_uploads'],
    ['Payment Details', 'sitter_payment_details'],
  ]);

  // the list of faq's loaded from the server
  const faqs = ref<Array<FAQResponse>>([]);

  // store the most recent batch of fetched payments.
  const payments = ref<Array<Payment>>([]);

  async function loadPayments() {
    payments.value = []; // not implemented
  }

  async function loadPrivacyPolicy() {
    privacyPolicy.value = 'NOT IMPLEMENTED'; // not implemented
  }

  async function loadTerms() {
    terms.value = 'NOT IMPLEMENTED'; // not implemented
  }

  async function loadFaqs() {
    await updateRefFromApi(faqs, SupportActionsController.getSitterFaqs);
  }

  // this will be retreived from the server in production
  const resourceTree = [
    [
      'Video Trainings',
      [
        [
          'How to use the app',
          'https://www.youtube.com/watch?v=1Q3wJY8nD9Q&list=PLZl3coZhX98oZ9ZQZ5Z0YXxv9kZ3Z3Q1d',
        ],
        [
          'Handling difficult children',
          'https://www.youtube.com/watch?v=1Q3wJY8nD9Q&list=PLZl3coZhX98oZ9ZQZ5Z0YXxv9kZ3Z3Q1d',
        ],
        [
          'Presentation is everything',
          'https://www.youtube.com/watch?v=1Q3wJY8nD9Q&list=PLZl3coZhX98oZ9ZQZ5Z0YXxv9kZ3Z3Q1d',
        ],
        [
          'Reporting incidents',
          'https://www.youtube.com/watch?v=1Q3wJY8nD9Q&list=PLZl3coZhX98oZ9ZQZ5Z0YXxv9kZ3Z3Q1d',
        ],
      ],
    ],
    [
      'Colouring In Pages',
      [
        [
          'Mammals',
          [
            [
              'Dogs',
              'http://www.supercoloring.com/coloring-pages/mammals/dogs',
            ],
            [
              'Cats',
              'http://www.supercoloring.com/coloring-pages/mammals/cats',
            ],
            [
              'Horses',
              'http://www.supercoloring.com/coloring-pages/mammals/horses',
            ],
            [
              'Elephants',
              'http://www.supercoloring.com/coloring-pages/mammals/elephants',
            ],
          ],
        ],
        ['Birds', 'http://www.supercoloring.com/coloring-pages/birds'],
        ['Reptiles', 'http://www.supercoloring.com/coloring-pages/reptiles'],
        ['Fish', 'http://www.supercoloring.com/coloring-pages/fish'],
        ['Insects', 'http://www.supercoloring.com/coloring-pages/insects'],
        ['Amphibian', 'http://www.supercoloring.com/coloring-pages/amphibian'],
        ['Mollusks', 'http://www.supercoloring.com/coloring-pages/mollusks'],
        [
          'Crustacean',
          'http://www.supercoloring.com/coloring-pages/crustacean',
        ],
      ],
    ],
    [
      "Childrens's Activities",
      [
        [
          'Games',
          'https://www.kidspot.com.au/things-to-do/collection/kids-games',
        ],
        [
          'Crafts',
          'https://www.kidspot.com.au/things-to-do/collection/kids-craft',
        ],
        [
          'Recipes',
          'https://www.kidspot.com.au/kitchen/recipes/collections/kids-recipes',
        ],
        [
          'Activities',
          'https://www.kidspot.com.au/things-to-do/collection/kids-activities',
        ],
      ],
    ],
    [
      'Invoice Suggestion Template',
      'https://www.google.com.au/search?q=invoice+template&tbm=isch',
    ],
    [
      'Employee Handbook',
      'https://www.google.com.au/search?q=employee+handbook&tbm=isch',
    ],
    [
      'The Report Card',
      'https://www.google.com.au/search?q=report+card&tbm=isch',
    ],
  ];

  return {
    privacyPolicy,
    terms,
    faqs,
    resourceTree,
    payments,
    sitter,
    sitterProfile,
    radioRoutes,
    loadPayments,
    loadPrivacyPolicy,
    loadTerms,
    loadFaqs,
    getBookingsRequiringAttention,
  };
});
