// get the plural based on number
export function pluralize(word: string, count: number, suffix = 's'): string {
  return count == 1 ? word : word + suffix;
}

// generate a string of an integer measurement eg '1 hour' or '14 laps' .
export function formatCountWithPlural(
  word: string,
  count: number,
  suffix = 's',
): string {
  return `${count} ${pluralize(word, count, suffix)}`;
}
