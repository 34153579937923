import { defineStore } from 'pinia';
import { updateRefFromApi } from '@/lib/api-request';
import { Ref, ref } from 'vue';
import { FAQResponse, InfoPage } from '@shared/franchises';
import { SupportActionsController } from '@/lib/api-routes';

export const useAdminStore = defineStore('admin', () => {
  // store the frequently asked questions
  const supportActionsRoute = 'support-actions';
  const currentPage = ref('');

  async function getFAQ(ref: Ref<FAQResponse | undefined>, id: string) {
    return await updateRefFromApi(ref, SupportActionsController.findOneFaq, {
      routeParams: { id },
    });
  }

  async function loadInfoPage(ref: Ref<InfoPage>, tag: string) {
    return await updateRefFromApi(ref, SupportActionsController.getInfoPages, {
      routeParams: { tag },
    });
  }

  return {
    currentPage,
    getFAQ,
    loadInfoPage,
    supportActionsRoute,
  };
});
