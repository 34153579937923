export * from './pluralize';
export * from './full-name';
export * from './capitalize-string';

export const ZERO_CURRENCY = '0.00';

export class Updatable {
  _dirty: boolean;
}

export type PaginationSpec = {
  recordsPerPage: number;
  startingRecord: number;
  totalRecords: number;
};

export type Paginated<T> = PaginationSpec & {
  data: Array<T>;
};

export class SchemaResponseFields {
  _id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
  isDeleted?: boolean;
}

export class EmergencyContactResponse {
  constructor(
    public name: string,
    public phone: string,
    public relationship: string,
  ) {}
}

export class AddressResponse {
  public unitNumber?: string | null;
  public streetNumber: string;
  public street: string;
  public city: string;
  public state: string;
  public postcode: string;
  public latitude?: number | null;
  public longitude?: number | null;
  public placeId?: string | null;
}

export const choiceHeardAboutUs = new Map([
  ['socialMedia', 'Social Media'],
  ['google', 'Google'],
  ['event', 'Event'],
  ['wordOfMouth', 'Word of Mouth'],
  ['currentCustomer', 'Current Client'],
  ['babysitter', 'Babysitter'],
  ['other', 'Other'],
]);

export const infoPagesTitles: Record<string, string> = {
  policy: 'Privacy Policy',
  customerTerms: 'Client Terms of Service',
  sitterTerms: 'Sitter Terms of Service',
  // Ongoing Terms is no longer in use
  // ongoingTerms: 'Ongoing Care Terms and Conditions',
};

export class PublishBookingPricingResponse {
  title: string;
  unit: string;
  quantity: string;
  customerCharge: string;
  sitterPayment: string;
}

export class DayOfEventResponse {
  bookingDateId: string;
  eventDatePricings: Array<PublishBookingPricingResponse>;
}

export class EventBookingCarerResponse {
  tags: string[];
  daysOfEvent: DayOfEventResponse[];
}

export class PublishEventAndPartyBookingResponse {
  bookingId: string;
  eventBookingCarers: Array<EventBookingCarerResponse>;
  parentId?: string;
}
